<template>
  <v-app>
    <v-navigation-drawer v-model="controls.drawer" width="300" floating app>
      <v-layout
        align-center
        justify-center
        column
        class="app-logo-container"
        style="height: 322px"
      >
        <div class="app-logo-sheet">
          <v-img
            :src="require('@/assets/images/logo.png')"
            width="78px"
            height="74px"
            class="ma-6"
          ></v-img>
        </div>
        <div class="app-company--name mt-8">Tradenow</div>
        <div class="app-project--name mt-2">Admin CMS</div>
      </v-layout>

      <v-list class="app-nav" flat>
        <v-list-item
          v-for="item in navItems"
          active-class="white"
          v-ripple="{ class: 'primary--text lighten-4' }"
          :key="item.label"
          :to="item.to"
          link
        >
          <v-list-item-content>
            <v-list-item-title class="pa-2 app-nav--item nav-item">{{
              item.label
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <v-list>
          <v-menu top offset-y>
            <template v-slot:activator="{ on }">
              <v-list-item two-line v-on="on">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ user.email }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ user.user_type }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list>
              <v-list-item
                :to="{ name: 'user.details', params: { id: user.id } }"
              >
                <v-list-item-title>Profile</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Settings</v-list-item-title>
              </v-list-item>
              <v-list-item @click="logoutConfirmDialog = true">
                <v-list-item-title>Log out</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-main class="main-content white">
      <slot />
    </v-main>

    <v-dialog v-model="logoutConfirmDialog" max-width="290">
      <v-card>
        <v-card-title class="headline">Logout</v-card-title>
        <v-card-text>
          <strong>Are you sure you want to logout ?</strong>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn text @click="logoutConfirmDialog = false">Cancel</v-btn>
          <v-btn color="primary" text @click="logout">Logout</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import ControlsMixin from '@/utils/mixins/Controls'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'MainLayout',

  mixins: [ControlsMixin],

  data() {
    return {
      defaultNavItems: [
        { label: 'Users', to: { name: 'users' } },
        { label: 'Customers', to: { name: 'customers' } },
        {
          label: 'Tradies',
          to: { name: 'tradies', query: { type: 'active' } },
        },
        { label: 'Invoices', to: { name: 'invoices' } },
        { label: 'Payments', to: { name: 'payments' } },
        { label: 'Business Areas', to: { name: 'business-areas' } },
        { label: 'Activity Log', to: { name: 'activity' } },
      ],
      logoutConfirmDialog: false,
    }
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    navItems() {
      if (this.user.is_temp_admin) {
        return [
          { label: 'Customers', to: { name: 'customers' } },
          {
            label: 'Tradies',
            to: { name: 'tradies', query: { type: 'active' } },
          },
        ]
      }
      return this.defaultNavItems
    },
  },

  methods: {
    ...mapActions({
      logout: 'auth/logout',
    }),
  },
}
</script>
